/*
  ! 1 file/project styles - App.css contains all project styles
  ! Default Styling for small devices
  ! Breakpoints Styling to adjust tablet and desktop display
*/

/** Generic Styles */
.clickable {
  cursor: pointer;
}

.relative {
  position: relative;
}

.loader-full-screen {
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.loader-svg {
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.loader-circle {
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

a {
  text-decoration: none;
  color: var(--primary-color);
}

.warning-badge {
  position: absolute;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.asterisk {
  color: var(--primary-color);
}

.chips-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.chip {
  border: 1px solid var(--light-gray);
  padding: .5rem 1rem;
  border-radius: 1.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: .5rem;
  align-items: center;
  font-weight: 600;
  /* white-space: nowrap; */
}

.chip.selected {
  background-color: var(--light-gray);
}

.input-photo-div {
  position: relative;
  width: 4rem;
  height: 4rem;
  border-radius: 0.5rem;
  border: 2px dashed var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.icon-input-photo {
  color: var(--gray);
  font-size: 2rem;
  cursor: pointer;
}

.input-photo {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
}

.photo-preview {
  width:  100%;
  position: relative;
}

.photo-preview.small {
  width: 4rem;
  height: 4rem;
}

.photo-preview img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  object-fit: contain;
}

.photo-preview .remove-icon {
  position: absolute;
  background-color: red;
  border-radius: 50%;
  top: -0.5rem;
  right: -0.5rem;
  cursor: pointer;
}

.checkbox-wrapper {
  background-color: var(--very-light-gray);
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  padding: 0.5rem 1rem;
}

.checkbox {
  display: grid;
  place-content: center;
  background-color: white;
  width: 1rem;
  height: 1rem;
  border: 2px solid var(--primary-color);
  border-radius: 0.25rem;
}

.checkbox.checked {
  background-color: var(--primary-color);
}

.wrapper-content-right {
  display: grid;
  justify-content: end;
  margin-block-end: 1rem;
}

.tabs-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.tabs-container>* {
  cursor: pointer;
  list-style: none;
  font-size: 1.25rem;
  position: relative;
}

.tabs-container>.active {
  position: absolute;
  height: 0.125rem;
  background-color: black;
  left: 0;
  bottom: 0;
  width: 100%;
  transition: 
    width 0.2s ease-in-out,
    transform 0.2s ease-in-out
  ;
}

.progress-bar {
  height: 0.6rem;
  width: 100%;
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, .4);
}

.progress-bar>* {
  height: 0.6rem;
  border-radius: 1rem;
  background-color: var(--light-pink);
}

.table {
  width: 100%;
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
  border-collapse: separate;
  border-spacing: 0;
}

.table > * > tr > * {
  padding-inline: 0.5rem;
}

.table-header > tr > * {
  background-color: var(--light-gray);
  text-align: left;
}

.table-header > tr > *:first-child {
  border-top-left-radius: 0.5rem;
}

.table-header > tr > *:last-child {
  border-top-right-radius: 0.5rem;
}

.table-body {
  vertical-align: middle;
}

.table-body > tr {
  box-shadow: 0 1px 0 0 var(--light-gray);
}

.table-body > tr:last-child {
  box-shadow: none;
}

.table-body > tr > td {
  width: fit-content;
  text-wrap: nowrap;
  padding: 0.5rem;
}

.table-body > tr > td:first-child { 
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5rem
}

.table-body .table-img-wrapper  { 
  width: 2rem;
  height: 2rem;
}

.table-body .table-img-wrapper > *  { 
  background-color: var(--gray);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.table-body > tr > td:first-child:hover > *:last-child { 
  border-bottom: 1px solid black;
}

.wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
  overflow: hidden;
}

/** Appbar Styles */
.appbar {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
  place-items: center;
  background-color: var(--primary-color);
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.4);
  color: white;
  padding: 0.6rem 0.4rem;
}

.appbar h1,
h2,
h3,
h4,
h5,
h6,
span {
  margin: 0;
  padding: 0;
}

.appbar img {
  width: 4rem;
  border-radius: 1rem;
}

.appbar>*:first-child {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 1rem;
}

.appbar>*:last-child {
  display: grid;
  justify-content: right;
}

#user-section {
  display: grid;
  grid-template-columns: repeat(2, auto);
  place-items: center;
  justify-content: right;
  gap: 1rem;
}

#appbar-user-info {
  width: max-content;
}

.appbar>*:last-child img {
  width: 3rem;
  border-radius: 50%;
}

.notifications-appbar-container {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-content: center;
  background-color: var(--red);
  color: white;
  border-radius: 50%;
  font-size: 0.8rem;
}

.notification-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  height: 80vh;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1005;
  width: 30vw;
  color: black;
  display: grid;
  gap: 1rem;
  grid-template-rows: auto 1fr;
}

.notification-dropdown-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
}

.notification-dropdown h3 {
  margin-top: 0;
  color: var(--primary-color);
}

.notification-dropdown-content {
  max-height: 100%;
  overflow-y: auto;
}

.notification-item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  border-left: 4px solid transparent;
  padding: 0.5rem;
}

.notification-text-container {
  display: grid;
  gap: 0.5rem;
}

.notification-datetime-container {
  display: grid;
  justify-content: end;
}

.notification-title {
  font-weight: bold;
}

.notification-subtitle {
  font-size: 0.9rem;
}

.notification-datetime {
  font-size: 0.8rem;
}

.notification-item:hover {
  border-left: 4px solid var(--primary-color);
}

/** Content Styles */
.content {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-body {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow-y: hidden;
  overflow-x: hidden;
}

.content-body > *:first-child {
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .4);
}

.content-body > * {
  padding: 1rem;
}

.content-body .navigation-title {
  display: flex;
  align-items: center;
}

.content-body .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-body .header > *:last-child {
  max-width: 10rem;
}

/** Drawer Styles */
.drawer-resizer {
  display: grid;
  place-content: center;
  position: sticky;
  background-color: var(--light-gray);
  border-radius: 0 0 0 1rem;
  margin-block-end: 0.5rem;
  width: 4rem;
  top: 0;
  left: 100%;
  z-index: 3;
}

.drawer-resizer>* {
  transition: transform 0.3s 0.3s ease-in-out;
}

.drawer {
  position: relative;
  background-color: var(--very-light-gray);
  box-shadow: 0 0 0.6rem 0.1rem rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  transition: width 0.3s ease-in-out;
}

.drawer-open .drawer {
  width: 20rem;
}

.drawer-open .drawer span {
  visibility: visible;
  display: block;
}

.drawer-open .drawer-resizer>* {
  transform: scaleX(-1);
}

.drawer-close .drawer {
  width: 4rem;
}

.drawer-close .drawer::-webkit-scrollbar {
  width: 0.2rem;
  height: 0.2rem;
}

.drawer ul {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
}

.drawer span {
  visibility: hidden;
  display: none;
}

.drawer-indicator {
  position: absolute;
  z-index: 0;
  background-color: var(--light-gray);
  left: 0;
  right: 0.5rem;
  border-start-end-radius: 100px;
  border-end-end-radius: 100px;
  transition: transform 0.2s ease-in-out;
}

.drawer-link {
  display: grid;
  grid-template-columns: 2rem 1fr;
  align-items: center;
  text-decoration: none;
  color: var(--dark-gray);
  padding: 1rem;
  margin-inline-end: 0.5rem;
  border-start-end-radius: 100px;
  border-end-end-radius: 100px;
  position: relative;
  overflow: hidden;
}

/** Auth Styles */
.wrapper-auth {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100dvh;
  width: 100%;
  place-content: center;
}

.wrapper-auth > * {
  padding: 1rem;
  width: 80vw;
  max-width: 600px;
}

.wrapper-auth h1 {
  font-size: 1.1rem;
  font-weight: normal;
  line-height: 1.2;
}

.wrapper-auth>*:first-child {
  display: grid;
  place-items: center;
  text-align: center;
}

.wrapper-auth>*:first-child img {
  width: 8rem;
}

.input-group {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.input-group label {
  display: grid;
  color: var(--dark-gray);
  margin-bottom: 0.25rem;
}

input {
  width: 100%;
  border-radius: 0.375rem;
  border: 1px solid var(--gray);
  outline: 0;
  padding: 0.75rem 2rem 0.75rem 1rem;
  box-sizing: border-box;
}

.password {
  position: relative;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 0.25rem;
  transform: translateY(-40%);
  cursor: pointer;
}

.input-group input:focus {
  border-color: var(--secondary-color);
}

.forgot {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--primary-color);
  margin: 8px 0 14px 0;
  font-size: 0.9rem;
}

.wrapper-auth>*:last-child {
  display: grid;
  align-items: baseline;
  text-align: center;
  font-size: 0.7rem;
  gap: 0.4rem;
  line-height: 1.1;
}

.wrapper-auth a {
  color: var(--primary-color);
  text-decoration: none;
}

.wrapper-auth a:hover {
  text-decoration: underline var(--primary-color);
}

.button {
  width: 100%;
  padding: 0.75rem;
  text-align: center;
  border-radius: 0.375rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.button.primary-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.button.secondary-button {
  background-color: transparent;
  color: var(--dark-gray);
  border: 1px solid var(--dark-gray);
}

.button.remove-button {
  background-color: transparent;
  color: var(--red);
  border: 1px solid var(--red);
}

/** Alert Styles */
.alert {
  position: absolute;
  width: 80%;
  width: fit-content;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  place-items: center;
  background-color: white;
  box-shadow: 0 0.0 1rem rgba(0, 0, 0, .4);
  padding: 0.75rem;
  border-radius: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
  z-index: 10;
}

.alert .error {
  color: var(--red);
}

.alert .success {
  color: var(--green);
}

.alert-confirm-wrapper {
  position: absolute;
  background-color: rgba(0, 0, 0, .4);
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
}

.alert-card {
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  width: 50vw;
}

.alert-card > *:last-child {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 0.5rem;
}

/** Dropdown Styles */
.dropdown-container {
  position: relative;
}

.dropdown-container.border {
  border: 1px solid var(--gray);
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.dropdown-container.border > *:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.placeholder {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: white;
  color: var(--dark-gray);
  padding: 0.25rem 0.5rem;
  border-radius: 0.325rem;
  max-width: 10rem;
}

.dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.4);
  top: 2.5rem;
  right: 0;
  left: 0;
  z-index: 10;
  word-wrap: break-word;
  border-radius: 0.4rem;
  overflow: hidden;
  max-height: 20rem;
  overflow-y: scroll;
}

.dropdown .item {
  cursor: pointer;
  padding: 1rem 0.5rem;
  border-bottom: 1px solid var(--light-gray);
}

.dropdown .item:hover {
  background-color: var(--very-light-gray);
}

.dropdown h4 {
  color: black;
}

.progress-bar-container {
  position: relative;
  display: grid;
  place-items: center;
  padding-inline-end: 2rem;
}

.card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, max-content));
  gap: 2rem;
}

.card {
  display: grid;
  gap: 1rem;
  text-align: center;
  border: 1px solid var(--light-gray);
  border-radius: 1rem;
  padding: .5rem;
  cursor: pointer;
  max-width: 16rem;
}

.card > * {
  overflow: hidden;
}

.card-image-wrapper {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

.card:hover {
  background-color: var(--very-light-gray);
}

.card img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 4 / 3;
}

/** Staff Styles */
.page-wrapper {
  overflow-x: auto;
}

.page-wrapper .form {
  display: grid;
  gap: 1rem;
}

.page-wrapper .form .form-button-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 0.5rem;
}

.page-wrapper .form h2 {
  color: var(--primary-color);
  padding-inline-start: 1rem;
}

.page-wrapper .form .form-section {
  display: grid;
  border: 1px solid var(--light-gray);
  border-radius: 0.5rem;
}

.page-wrapper .form .form-section > * {
  display: grid;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid var(--light-gray);
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  align-items: center;
}

.page-wrapper .form .form-section > * > *:first-child {
  display: flex;
}

.page-wrapper .form .form-section .full-space > *:last-child {
  grid-column: 2 span;
}

.page-wrapper .form .form-section .full-space.grid > *:last-child > * {
  border: 1px solid var(--light-gray);
  border-radius: 1rem;
  padding: .5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, max-content));
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.page-wrapper .form .form-section .full-space.grid > *:last-child > * > * {
  display: grid;
  max-width: 100%;
  height: min-content;
}

.page-wrapper .form .form-section> * > * > * {
  display: grid;
  gap: 1rem;
}

.page-wrapper .form .form-section > * > * {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 20rem));
  align-items: center;
}

.page-wrapper .form .form-section > *:last-child {
  border-bottom: none;
}

.calendarContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.calendarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  background-color: var(--very-light-gray);
  /* Replace with actual color value */
  padding: 4px;
  border-radius: 0.5rem;
}

.calendarHeaderTitle {
  font-weight: bold;
  font-size: 2rem;
}

.calendarHeaderButton {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
}

.monthGrid {
  display: grid;
  grid-template-columns: repeat(7, 14.28%);
  grid-template-rows: 100px;
  gap: 1px;
}

.dayContainer {
  display: flex;
  align-items: center;
  height: 100px;
  margin: 1px 0;
  background-color: white;
  outline: none;
  border: none;
  position: relative;
}

.dayContent {
  width: 95%;
  height: 80px;
  padding: 6px 0;
  border-top: 2px solid var(--gray);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
}

.day {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 4px;
}

.selectedDay {
  border: 1px solid var(--gray);
  border-top: 2px solid var(--gray);
  border-radius: 4px;
}

.today {
  background-color: var(--primary-color);
}

.dayText {
  font-size: 1rem;
  font-weight: bold;
}

.todayText {
  color: white;
  /* Replace with actual color value */
}

.dayNamesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 8px 0;
  background-color: var(--veryLightGray);
  /* Replace with actual color value */
}

.dayName {
  width: 14.28%;
  text-align: center;
  font-weight: bold;
}

.indicatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0 4px;
}

.indicator {
  flex: 1;
  height: 4px;
  border-radius: 100px;
  border: 1px solid var(--gray);
}

.indicatorText {
  font-size: 12px;
}

.weekViewContainer {
  width: 100%;
  position: relative;
  height: 100%;
  display: grid;
  overflow: hidden; /* Add this line */
}

.weekHeader {
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
}

.weekHeaderRow {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--very-light-gray);;
}

.weekTimeColumnHeader {
  background-color: var(--very-light-gray);
}

.dayHeader {
  cursor: pointer;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: var(--very-light-gray);
  width: 100%;
  padding-bottom: 10px;
}

.dayHeaderText {
  font-weight: bold;
  font-size: 14px;
}

.todayText {
  color: white;
}

.dateHeaderContainer {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.todayContainer {
  background-color: var(--primary-color);
}

.selectedContainer {
  border: 2px solid var(--gray);
}

.timeGridLine {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  z-index: 0;
  width: 100%;
}

.timeText {
  font-size: 12px;
  color: var(--gray);
}

.weekScrollContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%; /* Add this line */
}

.weekContainer {
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
  margin-top: 12px;
  position: relative; /* Add this line */
}

.weekDayScrollView {
  position: relative;
  overflow-y: scroll;
  height: 100%;
  display: flex;
  user-select: none; /* Add this line */
}

.dayColumn {
  border-left: 1px solid var(--light-gray);
}

.interventionsContainer {
  position: relative;
}

.intervention {
  position: absolute;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  z-index: 1000;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.1s ease-out;
  user-select: none; /* Add this line */
}

.intervention.dragging {
  opacity: 0.8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  pointer-events: none; /* Add this line */
}

.interventionText {
  font-size: 14px;
}

.currentTimeIndicator {
  position: absolute;
  left: -50px;
  right: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  z-index: 1000;
}

.currentTimeIndicatorCircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: red;
}

.currentTimeIndicatorLine {
  background-color: red;
}

.footer {
  background-color: var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 0;
  border-radius: 0.5rem;
}

.footerText {
  color: white;
}

.footerLabel {
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.calendarContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-block: 1rem;
}

.eventsContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 4px;
}

.hourSeparator {
  height: 1px;
  background-color: var(--gray);
}

.hourContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.eventStateIndicator {
  width: 10px;
  height: auto;
  border-radius: 100px;
  border: 1px solid var(--gray);
}

.event {
  display: flex;
  flex: 0.5;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border: 1px solid var(--gray);
  border-radius: 4px;
  padding: 1rem 2rem;
  cursor: pointer;
  position: relative;
}

.eventDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.eventTitle {
  font-size: 1rem;
  font-weight: bold;
}

.eventTime {
  font-size: 0.8rem;
}

.eventAvatar {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;
}

.eventAvatarText {
  font-size: 1rem;
  font-weight: bold;
  color: white;
}

.interventionDrawer__close {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  margin: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: var(--gray);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.interventionDrawer {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 1010;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.interventionDrawer__content {
  background-color: white;
  padding: 4rem 1rem 1rem;
  border-radius: 0.5rem;
  width: 50%;
  height: 100%;
  overflow-y: scroll;
}

.interventionDrawer__subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.interventionDrawer__section__label {
  font-size: 1.5rem;
  font-weight: bold;
}

.interventionDrawer__section__text {
  font-size: 1rem;
  color: var(--gray);
}

.interventionDrawer__section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--gray);
  border-radius: 0.5rem;
}

.interventionDrawer__section__item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--gray);
  padding-bottom: 0.5rem;
  padding: 1rem;
  position: relative;
}

.interventionDrawer__section__chips{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.interventionDrawer__section__photo {
  width: 200px;
  height: 150px;
  border-radius: 0.5rem;
  border: 1px solid var(--gray);
  overflow: hidden;
}

.interventionDrawer__section__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.interventionDrawer__section__state__container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.25rem;
  padding: 0.25rem;
  border-radius: 100px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.anomalies-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.anomaly-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--gray);
  border-radius: 0.5rem;
  padding: 1rem;
  cursor: pointer;
}

.anomaly-time-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.anomaly-type {
  font-size: 1rem;
  font-weight: bold;
}

.anomaly-description, .anomaly-solution {
  font-size: 0.8rem;
  color: var(--gray);
}

.anomaly-detail-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid var(--gray);
  border-radius: 0.5rem;
}

.anomaly-detail-section-item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid var(--gray);
  padding: 1rem;
}

.anomaly-detail-section-item-label {
  font-size: 1.2rem;
  font-weight: bold;
}

.anomaly-detail-section-item-value {
  font-size: 1.2rem;
  color: var(--gray);
}

/** Tablet Styles */
@media (min-width: 400px) {

  /** Appbar Styles */
  .appbar img {
    width: 6rem;
  }

  /** Auth Styles */
  .wrapper-auth h1 {
    font-size: 1.6rem;
  }

  .wrapper-auth>*:first-child img {
    width: 12rem;
  }

  .wrapper-auth>*:last-child {
    font-size: 0.9rem;
  }

  .page-wrapper .form .form-section > * {
    grid-template-columns: 1fr 2fr;
  }
}

/** Desktop Styles */
@media (min-width: 800px) {

  /** Appbar Styles */
  .appbar img {
    width: 8rem;
  }

  #user-section {
    display: grid;
    grid-template-columns: repeat(3, auto);
  }

  /** Auth Styles */
  .wrapper-auth h1 {
    font-size: 2rem;
  }

  .wrapper-auth>*:first-child img {
    width: 16rem;
  }

  .wrapper-auth>*:last-child {
    font-size: 1.1rem;
  }

  .page-wrapper .form .form-section > * {
    grid-template-columns: 1fr 4fr;
  }
}