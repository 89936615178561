@font-face {
  font-family: 'Visby';
  src: local("Visby"),
    url("./fonts/VisbyRegular.otf") format("truetype");
}

@font-face {
  font-family: 'Visby XB';
  src: local("Visby XB"),
    url("./fonts/VisbyExtrabold.otf") format("truetype");
}

@font-face {
  font-family: 'Archivo';
  src: url("./fonts/Archivo-Regular.ttf");
}

:root {
  /* Colors */
  --primary-color: #00ABD8;
  --primary-color-60: #00ABD899;
  --secondary-color: #7C7C7C;
  --dark-gray: #626266;
  --gray: #999999;
  --light-gray: #DADADA;
  --very-light-gray: #E9E9E9;
  --red: #E92C2C;
  --green: #5E9A37;
  --light-pink: #FAD3CB;

  /* Typography */
  --font-family: Visby;
  --font-size-base: 1rem;
  --line-height-base: 1.6;
}

html,
body,
#root {
  font-family: var(--font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--text-color);
  background-color: var(--background-color);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin: 0;
}

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.4rem;
}

::-webkit-scrollbar-thumb {
  background: var(--gray);
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray);
}